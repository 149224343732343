<template>
    <div>
        <main-header></main-header>
        <breadcrumbs></breadcrumbs>
        <seo-meta></seo-meta>
        <div class="wrapper wrapper--full wrapper--grey">
            <div class="wrapper wrapper--grey">

                <div class="advertise">

                    <div class="advertise__landing">
                        <h1>Advertise Your Car</h1>

                        <div class="advertise__landing_wrapper">
                            <div class="advertise__landing_panel">
                                <div class="panel-inner">
                                    <h2><span>Private Sellers</span>, sell your car for €5</h2>
                                    <p>Here at Terrific.ie we are striving to be the very best website in Ireland for selling a used car. If you are a private seller you can advertise your car for €5. If you have your car registration to hand you can kick things off by following the link below.</p>
                                </div>
                                <div class="advertise__landing_img private">
                                    <img src="../assets/img/adv-landing-car.png" class="sellers" alt="Advertising landing image" />
                                </div>
                                <div class="advertise__landing_cta">
                                    <router-link :to="'/advertise/private?start=1'" data-cy="private-advertiser">Advertise your car for €5</router-link>
                                </div>
                                <div class="advesrtise__landing_bottom-actions mobileonly">
                                    <router-link class="advertise__link" :to="{name: 'advertise-manage'}">Manage Existing Ads</router-link>
                                </div>

                            </div>
                            <div class="advertise__landing_panel">
                                <div class="panel-inner">
                                    <h2><span>Car Dealers</span>, register with us here</h2>
                                    <p>We want to serve Irish car dealers to the best of our ability and ultimately assist you in retaining cars profitability. Dealers please start he process of registering for Terrific.ie by following the below link. We promise to revert back within 24 hours.</p>
                                </div>
                                <div class="advertise__landing_img dealer">
                                    <img src="../assets/img/adv-landing-3cars.png" class="dealers" alt="Rectangle 282@2x.png" />
                                </div>
                                <div class="advertise__landing_cta">
                                    <router-link :to="{name: 'dealer-registration'}" data-cy="dealer-advertiser">Car dealers register here</router-link>
                                </div>
                            </div>
                        </div>

                        <div class="advertise__landing_bottom-actions desktoponly">
                            <router-link class="advertise__link" :to="{name: 'advertise-manage'}">Manage Existing Ads</router-link>
                        </div>

                    </div>
                </div>

            </div>
        </div>
        <main-footer></main-footer>
    </div>
</template>

<script>
    import MainHeader from '@/components/MainHeader.vue'
    import MainFooter from '@/components/MainFooter'
    import Breadcrumbs from "@/components/partials/Breadcrumbs"
    import SeoMeta from "@/components/partials/SeoMeta";
    export default {
        name: "AdvertiseLanding",
        components: {
            SeoMeta,
            MainHeader,
            MainFooter,
            Breadcrumbs
        },
        data: function () {
            return {
                title: "Used Cars, Cars in Ireland, Electric Cars, 7 Seaters, SUV’s | Terrific.ie",
                description: "Terrific.ie details used cars for sale in Ireland including electric cars, 7 seaters and SUV’s.",
                keywords: "Used Cars, Cars for Sale, Second Hand Cars, Electric Cars, Audi, BMW, Ford, Toyota, 7 Seaters,  Land Rover, People Carriers",
                robots: "all"
            }
        },
        serverPrefetch() {
            this.$ssrContext.title = this.title;
            this.$ssrContext.description = this.description;
            this.$ssrContext.keywords = this.keywords;
            this.$ssrContext.robots = this.robots;
            this.updateBreadcrumb();
            // this.getAd();
        },
        mounted(){
          this.updateBreadcrumb();
        },
        methods: {
            updateBreadcrumb(){
                this.$store.commit('breadcrumbs/setLeafLevelName',  'Advertise');
                this.$store.commit('breadcrumbs/setLevelOne',  false);
                this.$store.commit('breadcrumbs/setLevelTwo',  false);
                this.$store.commit('breadcrumbs/setLevelThree',  false);
                this.$store.commit('breadcrumbs/setLevelFour',  false);

            }
        }
    }
</script>

<style scoped>

</style>
